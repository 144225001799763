
































import {
  DIGITAL_HUMAN_LOADING,
  DIGITAL_HUMAN_SETTING,
  HANDLE_HYGEN_ICE,
  HYGEN_PEER_CONNECTION,
  HYGEN_RENDER_ID,
  HYGEN_SESSION_ID,
  HYGEN_SESSION_INFO,
  INIT_DIGITAL_HUMAN,
  INIT_HYGEN_SESSION,
  INIT_HYGEN_SESSION_ERROR,
  START_HYGEN_SESSION,
  STOP_HYGEN_SESSION
} from "@/store/modules/digital_human/constants";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROOT_ERROR, ROOT_LOADING } from "@/store/modules/root/constants";
import AppResourseNotFound from "@/components/shared/AppResourseNotFound.vue";
import { StartHygenSessionPayload } from "@/store/modules/digital_human/interfaces";
export default Vue.extend({
  name: "DigitalHuman",
  components: {
    AppResourseNotFound
  },
  data() {
    return {};
  },
  props: {
    interview_finished: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.init_digital_human();
    await this.initialize_session();
  },
  watch: {
    interview_finished() {
      if (this.interview_finished)
        this.stop_hygen_session({
          session_id: this.hygen_session_id
        });
    }
  },
  computed: {
    ...mapGetters({
      get_root_error: ROOT_ERROR,
      get_root_loading: ROOT_LOADING
    }),
    ...mapGetters("digital_human", {
      digital_human_setting: DIGITAL_HUMAN_SETTING,
      init_hygen_session_error: INIT_HYGEN_SESSION_ERROR,
      digital_human_loading: DIGITAL_HUMAN_LOADING,
      hygen_session_id: HYGEN_SESSION_ID,
      hygen_session_info: HYGEN_SESSION_INFO,
      get_peer_connection: HYGEN_PEER_CONNECTION,
      get_render_id: HYGEN_RENDER_ID
    })
  },
  methods: {
    ...mapActions("digital_human", {
      init_digital_human: INIT_DIGITAL_HUMAN,
      init_hygen_session: INIT_HYGEN_SESSION,
      start_hygen_session: START_HYGEN_SESSION,
      handle_hygen_ice: HANDLE_HYGEN_ICE,
      stop_hygen_session: STOP_HYGEN_SESSION
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_loading: ROOT_LOADING
    }),
    ...mapMutations("digital_human", {
      set_init_hygen_session_error: INIT_HYGEN_SESSION_ERROR,
      set_hygen_session_id: HYGEN_SESSION_ID,
      set_hygen_session_info: HYGEN_SESSION_INFO,
      set_peer_connection: HYGEN_PEER_CONNECTION,
      set_render_id: HYGEN_RENDER_ID
    }),
    // Function to initialize the session
    async initialize_session() {
      // API call to initialize the session
      const resp = await this.init_hygen_session({
        quality: "high",
        avatar_name: this.digital_human_setting.bot_avatar,
        voice: {
          voice_id: this.digital_human_setting.bot_voice_id
        }
      });
      const session_id = resp?.session_id; // Get the session id from the response
      // If response is null or session id is null, set the error
      if (!resp || !session_id) {
        this.set_init_hygen_session_error("Error in initializing Bot session");
        this.set_root_error("Error in initializing Bot session");
        return;
      }
      this.set_hygen_session_id(session_id); // Set the session id
      this.set_hygen_session_info(resp); // Set the session info
      const peerConnection = new RTCPeerConnection({
        iceServers: this.hygen_session_info.ice_servers2
      });
      this.set_peer_connection(peerConnection); // Set the peer connection
      this.get_peer_connection.ontrack = (event: any) => {
        const mediaElement = this.$refs.mediaElement as HTMLVideoElement;
        if (
          (event.track.kind === "audio" || event.track.kind === "video") &&
          mediaElement
        ) {
          mediaElement.srcObject = event.streams[0];
        }
      };
      this.get_peer_connection.onconnectionstatechange = (event: any) => {
        if (this.get_peer_connection?.connectionState === "connected") {
          this.render_canvas();
        }
      };
      // Set server's SDP as remote description
      const remoteDescription = new RTCSessionDescription(
        this.hygen_session_info.sdp
      );
      await this.get_peer_connection.setRemoteDescription(remoteDescription);
      await this.start_session(); // Start the session
    },
    // Function to start session
    async start_session() {
      // Create and set local SDP description
      const localDescription = await this.get_peer_connection.createAnswer();
      await this.get_peer_connection.setLocalDescription(localDescription);
      // When ICE candidate is available, send to the server
      this.get_peer_connection.onicecandidate = async ({
        candidate
      }: {
        candidate: any;
      }) => {
        if (candidate) {
          await this.handle_hygen_ice({
            session_id: this.hygen_session_id,
            candidate
          });
        }
      };
      const start_hygen_session: StartHygenSessionPayload = {
        session_id: this.hygen_session_id,
        sdp: localDescription
      };
      const resp = await this.start_hygen_session(start_hygen_session);
      if (!resp) {
        this.set_init_hygen_session_error("Failed to start Bot session");
        this.set_root_error("Failed to start Bot session");
        return;
      }
    },
    // Function to remove background
    render_canvas() {
      const canvasElement = this.$refs.canvasElement as HTMLCanvasElement;
      const mediaElement = this.$refs.mediaElement as HTMLVideoElement;

      const curRenderID = Math.trunc(Math.random() * 1000000000);
      this.set_render_id(curRenderID);
      const renderID = this.get_render_id;
      const ctx = canvasElement.getContext("2d", {
        willReadFrequently: true
      }) as any;

      function processFrame() {
        if (curRenderID !== renderID) return;
        if (!ctx) return;

        canvasElement.width = mediaElement.videoWidth;
        canvasElement.height = mediaElement.videoHeight;

        canvasElement.style.maxHeight = window.innerHeight + "px";
        canvasElement.style.maxWidth = "100%";
        ctx.drawImage(
          mediaElement,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        (ctx as any).getContextAttributes().willReadFrequently = true;
        const imageData = ctx.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          const red = data[i];
          const green = data[i + 1];
          const blue = data[i + 2];

          // You can implement your own logic here
          if (green > 90 && red < 90 && blue < 90) {
            // if (isCloseToGray([red, green, blue])) {
            data[i + 3] = 0;
          }
        }

        ctx.putImageData(imageData, 0, 0);

        requestAnimationFrame(processFrame);
      }

      processFrame();
      this.hideElement(mediaElement);
      this.showElement(canvasElement);
    },
    isCloseToGreen(color: number[]) {
      const [red, green, blue] = color;
      return green > 90 && red < 90 && blue < 90;
    },
    hideElement(element: HTMLElement) {
      element.classList.add("hide");
      element.classList.remove("show");
    },
    showElement(element: HTMLElement) {
      element.classList.add("show");
      element.classList.remove("hide");
    }
  },
  beforeDestroy() {
    if (this.hygen_session_id)
      this.stop_hygen_session({
        session_id: this.hygen_session_id
      });
  }
});
